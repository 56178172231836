import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';

import { configurationSelectors, State } from '@wam/shared';
import { authenticationSelectors } from '@wam/authentication';
import { isParent } from '@app/authentication/user.data';

@Injectable({
  providedIn: 'root',
})
export class EulaService {
  constructor(private store: Store<State>) {}

  isEulaAccepted(): Observable<boolean> {
    return this.store.select(configurationSelectors.getUserConfiguration<boolean>('eula'));
  }

  mustShowEula(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(authenticationSelectors.getCurrentUser)),
      this.isEulaAccepted(),
    ]).pipe(
      map(([currentUser, accepted]) => isParent(currentUser) && !accepted),
      take(1),
    );
  }
}
